:root {
  --mdc-theme-primary: #009688;
  --mdc-theme-on-primary: #FFFFFF;
}
.--mdc-theme-primary {
  color: #009688;
}
.app {
  margin: 0;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
body {
  background-color: #fff;
  color: #616C6F;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: #2C3335;
}
b,
strong {
  font-weight: 500;
}
button:focus {
  outline: none;
}
.lander {
  background-color: #fff;
}
.nav-link {
  display: inline-block;
  cursor: pointer;
}
.tab .nav-link.active {
  font-weight: bold;
}
.navcustom {
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.075);
  background-color: #009688;
}
.navbar-toggler {
  border-color: #fff !important;
}
.navbar-toggler:hover {
  cursor: pointer;
  border-color: #fff;
}
.nav-btn {
  background: none;
  border: none;
  color: #fff;
}
.nav-btn:focus {
  outline: none;
}
.navbar-container {
  position: fixed;
  top: 0;
  right: 0;
  padding: 0;
  transition: all 0.5s;
  background-color: #fff;
  overflow-x: hidden;
  z-index: 1050;
}
.navbar-content {
  padding: 1rem;
}
.nav-toggle {
  cursor: pointer;
  position: absolute;
  bottom: 20px;
}
.nav-toggle:hover {
  color: #009688;
}
.nav-link2 {
  color: #616C6F;
  font-size: 1.3rem;
  border-radius: 30px;
  width: 100%;
  cursor: pointer;
}
.nav-link2.active {
  background-color: #00968757;
  color: #009688;
}
.nav-link2:hover {
  color: #616C6F;
  background-color: #676e7010;
}
.avatar {
  border-radius: 100%;
  width: 30%;
  height: auto;
}
.dashboard-menu {
  background-color: #fff;
  background-image: url('./assets/svgs/tournament-header.svg');
  width: 100%;
  color: #616C6F;
  box-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.075);
  padding: 1rem 1.5rem 0;
  margin-bottom: 0px;
  font-size: 2.5rem;
  padding-bottom: 0.5rem;
}
.dashboard-body {
  min-height: 70vh;
}
.blogpage-body img {
  max-width: 100%;
  height: auto;
}
.bloghome-body {
  min-height: 60vh;
  background-color: #f5f5f5;
}
.blog-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.blog-card {
  padding: 1rem;
}
.tournament-body {
  background-color: #f5f5f5;
}
.icon-button {
  position: absolute;
  color: #616C6F;
  right: 0px;
  bottom: 5px;
  border: none;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  background: none;
}
.icon-button:active,
.icon-button:focus {
  outline: none;
  border: none;
}
.modal-header {
  color: #fff;
  background-color: #ee5522;
  background-image: url("./assets/svgs/SunTornado.svg");
  background-size: cover;
}
.modal input[type="submit"] {
  color: #fff;
  background-color: #ee5522;
  background-image: url("./assets/svgs/SunTornado.svg");
}
.bg-theme {
  color: #fff;
  background-color: #009688;
}
.login {
  border-radius: 10px;
  border: none;
  margin: 10px;
  background-color: #f5f5f5;
}
.login .card-header {
  color: #fff;
  border-radius: 10px 10px 0 0;
  background-color: #009688;
}
.login .card-footer {
  color: #2C3335;
  border-radius: 0 0 10px 10px;
}
.tournament-card {
  border: 1px solid #00000033;
  margin: 10px 0px;
  box-shadow: 0 0.25rem 0.5rem #cad0d1;
  transition: all 0.3s;
  min-height: 200px;
}
.tournament-card:hover {
  border: 1px solid #B2DFDB;
  box-shadow: 0 0.375rem 0.5rem #afb4b4;
  transform: scale(0.99);
}
.tournament-card .card-header,
.tournament-card .card-footer {
  padding: 5px 10px;
  background-color: #fff;
}
.create-tournament:hover {
  border: 1px solid #B2DFDB;
  box-shadow: 0 0.375rem 0.5rem #afb4b4;
  transform: scale(0.99);
}
.create-tournament {
  cursor: pointer;
  display: flex;
  max-height: 200px!important;
  align-items: center;
  justify-content: center;
  border: 1px solid #00000033;
  margin: 10px;
  box-shadow: 0 0.25rem 0.5rem #cad0d1;
  transition: all 0.3s;
}
.tournament-header {
  background-color: #fff;
  background-image: url('./assets/svgs/tournament-header.svg');
  width: 100%;
  color: #616C6F;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.075);
  padding: 1rem 1.5rem 0;
  margin-bottom: 0px;
}
.tournament-header-link {
  padding: 0.3rem 0.5rem;
  font-size: 1.1rem;
  text-decoration: none;
  color: #2C3335;
}
.tournament-header-link:hover {
  text-decoration: none;
  color: #2C3335;
  background-color: #676e7010;
}
.tournament-header-link.active {
  border-bottom: 3px solid #009688;
  color: #009688;
}
.tournament-header-link.active:hover {
  background-color: #00968757;
}
@media (min-width: 600px) {
  .tournament-header-link {
    font-size: 1.4rem;
    padding: 0.3rem 0.7rem;
  }
}
.jumbotron {
  background-color: #009688;
  color: #FFFFFF;
  padding-bottom: 5rem;
  height: 95vh!important;
  border-radius: 0;
}
.jumbotron-heading {
  font-size: 22px;
}
.jumbotron-text {
  font-family: 'roboto', sans-serif;
  font-weight: 300;
  padding: 5px initial;
}
.jumbotron-img {
  height: 80px;
}
@media (min-width: 600px) {
  .jumbotron-img {
    height: 150px;
  }
}
.faq {
  padding: 0;
  margin: 0;
}
.faq-question {
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}
.faq-question:hover {
  color: #009688;
}
@media (min-width: 600px) {
  .jumbotron-heading {
    font-size: 32px;
  }
  .faq-question {
    font-size: 22px;
  }
}
@media (min-width: 1024px) {
  .jumbotron-heading {
    font-size: 44px;
  }
  .faq-question {
    font-size: 28px;
  }
}
.action-button {
  display: inline-block;
  vertical-align: middle;
  background-color: #009688;
  border: 2px solid #fff;
  border-radius: 3px;
  color: #FFFFFF;
  text-align: center;
  font-size: 1.5rem;
  padding: 0.5rem 1.2rem;
  width: 260px;
  height: auto;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}
.action-button:focus {
  outline: none;
}
.action-button:active {
  transform: translateY(5px);
}
.action-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.action-button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.action-button:hover span {
  padding-right: 25px;
}
.action-button:hover span:after {
  opacity: 1;
  right: 0;
}
@media (max-width: 600px) {
  .action-button {
    width: 230px;
    font-size: 1rem;
  }
}
.footer {
  margin: 0px auto;
  border-top: 1px solid #E5E5E5;
  padding: 30px 0 30px 0;
  background-color: #F8F9FA;
  border-radius: 0;
}
.footer-link {
  color: #616C6F;
  font-size: 1.1rem;
}
.footer-link:hover {
  text-decoration: none;
  color: #009688;
}
.footer2 {
  background-color: #F8F9FA;
  color: #212121;
}
.avitechlab {
  font-size: 1.2rem;
  font-weight: 600;
  color: #212121;
}
.bgdark {
  background-color: #2C3335;
}
.bg-primaryapp {
  background-color: #009688;
}
.pane {
  background-color: #2C3335;
  color: #fff;
}
.dropdown-container {
  display: flex;
  justify-content: center;
}
.dropdown-content {
  display: none;
  background-color: #fff;
  width: 99%;
  border: 0.1mm solid rgba(179, 175, 175, 0.521);
  border-top: 0;
  max-height: 180px;
  overflow: auto;
}
.team-search-card {
  cursor: pointer;
}
.team-search-card:hover {
  background-color: #676e7010;
}
.contact {
  width: 100%;
  transition: all 0.4s;
}
.contat button[type="submit"] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 160px;
  height: 42px;
  background-color: #009688;
  font-size: 14px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  padding-top: 5px;
  border: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.contact button[type="submit"]:active {
  border: 3px solid #2C3335;
}
.contact button[type="submit"]:hover {
  background-color: #00796B;
}
.contact button[type="submit"]:focus {
  outline: none!important;
}
.contact button[type="submit"].disabled {
  background-color: #00968757;
  color: #009688;
}
.contact input[type="text"],
.contact textarea {
  width: 100%;
  border: 1px solid #E5E5E5;
  padding: 10px 10px;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  -moz-transition: all 0.6s;
  transition: all 0.6s;
}
.contact input[type="text"]:active,
.contact textarea:active {
  outline: none!important;
  border: 1px solid #009688;
}
.contact input[type="text"]:focus,
.contact textarea:focus {
  outline: none!important;
  border: 1px solid #009688;
}
.contact input[type="text"].invalid,
.contact textarea.invalid {
  border: 1px solid #d13c3c;
}
.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-facebook:focus,
.btn-facebook.focus {
  color: #fff;
  background-color: #2d4373;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-facebook:hover {
  color: #fff;
  background-color: #2d4373;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-facebook:active,
.btn-facebook.active,
.open > .dropdown-toggle.btn-facebook {
  color: #fff;
  background-color: #2d4373;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-facebook:active:hover,
.btn-facebook.active:hover,
.open > .dropdown-toggle.btn-facebook:hover,
.btn-facebook:active:focus,
.btn-facebook.active:focus,
.open > .dropdown-toggle.btn-facebook:focus,
.btn-facebook:active.focus,
.btn-facebook.active.focus,
.open > .dropdown-toggle.btn-facebook.focus {
  color: #fff;
  background-color: #23345a;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-facebook:active,
.btn-facebook.active,
.open > .dropdown-toggle.btn-facebook {
  background-image: none;
}
.btn-facebook.disabled:hover,
.btn-facebook[disabled]:hover,
fieldset[disabled] .btn-facebook:hover,
.btn-facebook.disabled:focus,
.btn-facebook[disabled]:focus,
fieldset[disabled] .btn-facebook:focus,
.btn-facebook.disabled.focus,
.btn-facebook[disabled].focus,
fieldset[disabled] .btn-facebook.focus {
  background-color: #3b5998;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-facebook .badge {
  color: #3b5998;
  background-color: #fff;
}
.btn-linkedin {
  color: #fff;
  background-color: #007bb6;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin:focus,
.btn-linkedin.focus {
  color: #fff;
  background-color: #005983;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin:hover {
  color: #fff;
  background-color: #005983;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin:active,
.btn-linkedin.active,
.open > .dropdown-toggle.btn-linkedin {
  color: #fff;
  background-color: #005983;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin:active:hover,
.btn-linkedin.active:hover,
.open > .dropdown-toggle.btn-linkedin:hover,
.btn-linkedin:active:focus,
.btn-linkedin.active:focus,
.open > .dropdown-toggle.btn-linkedin:focus,
.btn-linkedin:active.focus,
.btn-linkedin.active.focus,
.open > .dropdown-toggle.btn-linkedin.focus {
  color: #fff;
  background-color: #00405f;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin:active,
.btn-linkedin.active,
.open > .dropdown-toggle.btn-linkedin {
  background-image: none;
}
.btn-linkedin.disabled:hover,
.btn-linkedin[disabled]:hover,
fieldset[disabled] .btn-linkedin:hover,
.btn-linkedin.disabled:focus,
.btn-linkedin[disabled]:focus,
fieldset[disabled] .btn-linkedin:focus,
.btn-linkedin.disabled.focus,
.btn-linkedin[disabled].focus,
fieldset[disabled] .btn-linkedin.focus {
  background-color: #007bb6;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin .badge {
  color: #007bb6;
  background-color: #fff;
}
.btn-twitter {
  color: #fff;
  background-color: #55acee;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-twitter:focus,
.btn-twitter.focus {
  color: #fff;
  background-color: #2795e9;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-twitter:hover {
  color: #fff;
  background-color: #2795e9;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-twitter:active,
.btn-twitter.active,
.open > .dropdown-toggle.btn-twitter {
  color: #fff;
  background-color: #2795e9;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-twitter:active:hover,
.btn-twitter.active:hover,
.open > .dropdown-toggle.btn-twitter:hover,
.btn-twitter:active:focus,
.btn-twitter.active:focus,
.open > .dropdown-toggle.btn-twitter:focus,
.btn-twitter:active.focus,
.btn-twitter.active.focus,
.open > .dropdown-toggle.btn-twitter.focus {
  color: #fff;
  background-color: #1583d7;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-twitter:active,
.btn-twitter.active,
.open > .dropdown-toggle.btn-twitter {
  background-image: none;
}
.btn-twitter.disabled:hover,
.btn-twitter[disabled]:hover,
fieldset[disabled] .btn-twitter:hover,
.btn-twitter.disabled:focus,
.btn-twitter[disabled]:focus,
fieldset[disabled] .btn-twitter:focus,
.btn-twitter.disabled.focus,
.btn-twitter[disabled].focus,
fieldset[disabled] .btn-twitter.focus {
  background-color: #55acee;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-twitter .badge {
  color: #55acee;
  background-color: #fff;
}
.btn-github {
  color: #fff;
  background-color: #444;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-github:focus,
.btn-github.focus {
  color: #fff;
  background-color: #2b2b2b;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-github:hover {
  color: #fff;
  background-color: #2b2b2b;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-github:active,
.btn-github.active,
.open > .dropdown-toggle.btn-github {
  color: #fff;
  background-color: #2b2b2b;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-github:active:hover,
.btn-github.active:hover,
.open > .dropdown-toggle.btn-github:hover,
.btn-github:active:focus,
.btn-github.active:focus,
.open > .dropdown-toggle.btn-github:focus,
.btn-github:active.focus,
.btn-github.active.focus,
.open > .dropdown-toggle.btn-github.focus {
  color: #fff;
  background-color: #191919;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-github:active,
.btn-github.active,
.open > .dropdown-toggle.btn-github {
  background-image: none;
}
.btn-github.disabled:hover,
.btn-github[disabled]:hover,
fieldset[disabled] .btn-github:hover,
.btn-github.disabled:focus,
.btn-github[disabled]:focus,
fieldset[disabled] .btn-github:focus,
.btn-github.disabled.focus,
.btn-github[disabled].focus,
fieldset[disabled] .btn-github.focus {
  background-color: #444;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-github .badge {
  color: #444;
  background-color: #fff;
}
.btn-email {
  color: #fff;
  background-color: #afb4b4;
}
.btn-email:hover {
  color: #fff;
}
.btn-social-icon {
  margin: 5px!important;
}
.dev-info {
  background-color: #F8F9FA;
}
.dev-image {
  width: auto;
  height: 250px;
}
.dev-text {
  font-size: 1.5rem;
}
@media (max-width: 600px) {
  .dev-text {
    font-size: 1.2rem;
  }
}
.fa-spin-reverse {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  animation-direction: reverse;
}
.implementation-table {
  display: table;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 16px 0 15px;
  width: 100%;
}
.implementation-table tr td {
  padding: 32px;
}
.implementation-table tr {
  border-bottom: 1px solid #dadce0;
}
.t-number {
  background: #BDBDBD;
  border-radius: 50%;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 36px;
  margin-right: 20px;
  text-align: center;
  width: 36px;
  padding: 10px!important;
}
.logo {
  height: 36px;
  width: 36px;
}
.logo-text {
  font-size: 1.25rem;
  color: #fff;
}
.logo-text:hover {
  text-decoration: none!important;
}
@media (max-width: 600px) {
  .implementation-table tr td {
    padding: 18px;
  }
  .logo {
    height: 24px;
    width: 24px;
  }
  .logo-text {
    font-size: 1.1rem;
  }
}
.reg-form-container {
  background-color: #f5f5f5;
  margin: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
}
.reg-form {
  max-width: 600px;
  background-color: #fff;
  margin: 0px 10px 0px;
  border-radius: 10px;
  border-bottom: 0.5rem solid #009688;
  border-top: 0.5rem solid #009688;
  box-shadow: 0.105rem 0.105rem 0.2rem #bdbdbdbe;
  padding: 15px;
}
.grecaptcha-badge {
  opacity: 0;
}
.notification-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1021;
}
.notification-btn {
  position: absolute;
  cursor: pointer;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #e64a19;
  color: #fff;
  box-shadow: 0.16rem 0.16rem 0.2rem #00968757;
}
.notification-btn:hover {
  box-shadow: 0.2rem 0.2rem 0.3rem #616C6F;
}
@keyframes bubble {
  0% {
    box-shadow: 0px 0px 0 5px #B2DFDB;
  }
  100% {
    box-shadow: 0 0 0 10px #B2DFDB;
  }
}
.has-notification {
  animation: bubble 0.5s ease-in-out infinite alternate;
}
.has-notification::after {
  content: '1';
  background-color: red;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0.1rem;
}
@media (min-width: 600px) {
  .notification-btn {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 1.2rem;
  }
  .has-notification::after {
    font-size: 1rem;
  }
}
.notification {
  background-color: #fff;
  width: 300px;
  overflow: auto;
  min-height: 100px;
  position: absolute;
  bottom: 55px;
  right: 0;
  padding: 0.8rem;
  border: 1px solid #2b2d2e56;
  box-shadow: 0.15rem 0.15rem 0.375rem #616c6f38;
}
.fa-messenger {
  content: url('https://api.iconify.design/fa-brands:facebook-messenger.svg??color=%23fff&height=16');
  vertical-align: -0.125em;
}
.send-facebook {
  background-color: #0084FF;
  color: #fff;
  padding: 0.5rem;
  font-family: Roboto;
  border-radius: 5px;
  height: 2.4rem;
  transition: all 0.2s;
  margin-bottom: 5px;
}
.send-facebook:hover {
  cursor: pointer;
  background-color: #047beb;
}
.send-instagram {
  background-image: linear-gradient(to bottom left, #7822B7, #E1002B 80%, #E88446, #F1D01A);
  color: #fff;
  padding: 0.5rem;
  font-family: Roboto;
  border-radius: 5px;
  height: 2.4rem;
  transition: all 0.2s;
  margin-bottom: 5px;
}
.send-instagram:hover {
  cursor: pointer;
  background-image: linear-gradient(to bottom left, #7822B7, #E1002B 80%, #E88446, #F1D01A);
}
