.stats-home {
  background-color: #fafafa;
}
#statstemp {
  position: absolute;
  left: 3000px;
}
#stats {
  border-radius: 20px;
}
.download-old {
  color: var(--mdc-theme-primary);
  text-decoration: underline;
  cursor: pointer;
}
.stats-content {
  border-radius: 20px;
}
.stats-table {
  border-spacing: 0.3rem;
  border-collapse: separate;
  margin: auto;
}
.stats-table,
.stats-table th,
.stats-table tr,
.stats-table td {
  padding: 0.2rem;
  border-radius: 2px;
  font-size: 0.8rem;
  font-weight: 400;
}
.stats-table .th-title {
  font-size: 1.5rem;
  font-weight: 500;
}
.stats-table .td {
  text-align: center;
  border-radius: 2px;
}
.stats-table .team {
  text-align: left;
  padding-left: 0.5rem;
  padding-right: 2rem;
  font-weight: 600;
}
.stats-table.download .team {
  width: 16rem;
}
@media (min-width: 600px) {
  .stats-table,
  .stats-table th,
  .stats-table tr,
  .stats-table td {
    padding: 0.5rem 1rem;
    border-radius: 2px;
    font-size: 1rem;
    font-weight: 400;
  }
  .stats-table th {
    font-weight: 600;
  }
  .stats-table .team {
    font-weight: 600;
  }
  .stats-table .tourney-name {
    font-size: 2.5rem;
  }
  .stats-table .organiser {
    font-size: 1.6rem;
  }
}
.top-teams {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.top-teams .mdc-card {
  margin: 0.5rem 1rem 1rem 0.5rem;
}
.stats-card {
  border-radius: 10px;
  background-color: #ffffff;
}
/*Point Table themes Themes*/
/* Theme : default */
.default {
  background-image: url('./assets/images/bg-1.jpg');
}
.default .credit {
  text-align: center;
  width: 100%;
  font-size: 1.1rem;
  color: white;
  letter-spacing: 3px;
  font-family: roboto;
}
.default .stats-table,
.default .stats-table .tourney-name,
.default .stats-table .organiser {
  color: #0c0500 !important;
}
.default .stats-table .th {
  background-color: rgba(200, 202, 199, 0.85);
}
.default .stats-table .th-title {
  color: #231102;
  background: linear-gradient(to bottom right, #ddc438 0%, #dd9c38 100%);
}
.default .stats-table .td {
  background: linear-gradient(to bottom right, #627387 0%, #27394D 100%);
  color: white;
}
.default .stats-table .team {
  color: #231102;
  background: linear-gradient(to bottom right, #ddc438 0%, #dd9c38 100%);
}
